import React, { useState, useEffect } from 'react';
import {
    FormLabel,
    Button,
    TextInput,
    FormGroup,
    Checkbox,
    MultiSelect,
    Dropdown, InlineNotification, Link,
} from 'carbon-components-react';
import { Column, Row } from "./Layout";
import { spacing } from "../constants/styles.constants";
import { storyCategories, defaultStoryLocation } from "../constants/form.constants";
import endpoints from "../constants/endpoints.constants";
import Loading from "./Loading";
import MapMarker from "./MapMarker";
import MediaUpload from "./MediaUpload";
import style from "./StoryDetails.module.scss";
import { useGlobalState } from "../hooks/useGlobalState";

// eslint-disable-next-line
export default (props) => {
    const { storyData, createdStory } = props;
    const isEdit = Object.keys(storyData).length > 0
    const [existingFileUrlVideo, setExistingFileUrlVideo] = useState('')
    const [existingFileUrlPhoto, setExistingFileUrlPhoto] = useState('')
    const [isMediaVideoUploading, setIsMediaVideoUploading] = useState(false);
    const [isMediaPhotoUploading, setIsMediaPhotoUploading] = useState(false);
    const [agreedTermsConditions, setAgreedTermsConditions] = useState(false)
    const [, { setStoryPinLocation }] = useGlobalState();
    const [formData, setFormData] = useState({
        storyName: '',
        storyLink: '',
        storyCategories: '',
        storyPrimaryCategory: '',
        storyLatitude: defaultStoryLocation.latitude,
        storyLongitude: defaultStoryLocation.longitude,
        storyDescription: '',
        thumbnailMediaId: 0,
        storyMediaId: 0,
        agreedToTermsAndConditions: false,
    });

    useEffect(() => {
        if (isEdit) {
            setFormData({
                storyName: storyData.storyName,
                storyLink: storyData.storyLink,
                storyCategories: storyData.storyCategories,
                storyPrimaryCategory: storyData.storyPrimaryCategory,
                storyLatitude: storyData.storyLatitude,
                storyLongitude: storyData.storyLongitude,
                storyDescription: storyData.storyDescription,
                thumbnailMediaId: storyData.thumbnailMedia.id,
                storyMediaId: storyData.storyMediaProcessed.id,
                agreedToTermsAndConditions: false,
                isFilmFestival: storyData.isFilmFestival,
            })
            setStoryPinLocation({
                location: {
                    lat: storyData.storyLatitude,
                    lng: storyData.storyLongitude,
                }
            })
            setExistingFileUrlVideo(endpoints.mediaUpload({ objectId: storyData.storyMediaProcessed.objectKey }))
            setExistingFileUrlPhoto(endpoints.mediaUpload({ objectId: storyData.thumbnailMedia.objectKey }))
        }
    }, [setStoryPinLocation, props.adData, storyData, isEdit])

    const isFormDisabled = (props.isLoading || props.isSuccess);

    return (
        <>
            <Row space={spacing.medium}>
                <Column>
                    <h3 className={style.bottomPadding}>New Story</h3>
                    <p>Enter further details about the Story you want to Upload</p>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isLoading={props.isLoading}
                        isSuccess={props.isSuccess}
                        isError={props.isError}
                        errorMessage={props.errorMessage}
                    />
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            helperText="Set a name for your Story"
                            id="storyName"
                            labelText="Story Name"
                            placeholder="Story Name"
                            value={formData.storyName}
                            disabled={isFormDisabled}
                            onChange={(event) => setFormData({
                                ...formData,
                                storyName: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            type={"url"}
                            helperText="The link to your Stories Website"
                            id="storyLink"
                            labelText="Story Link (Optional)"
                            placeholder="Story Link"
                            disabled={isFormDisabled}
                            value={formData.storyLink}
                            onChange={(event) => setFormData({
                                ...formData,
                                storyLink: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            helperText="Your Stories Description"
                            id="storyDescription"
                            labelText="Story Description"
                            placeholder="Story Description"
                            disabled={isFormDisabled}
                            value={formData.storyDescription}
                            onChange={(event) => setFormData({
                                ...formData,
                                storyDescription: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <label className={'bx--label'}>Choose Story Location</label>
                    <div className={'bx--form__helper-text'}>
                        Drag the pin on the map to select your story location
                    </div>
                    <MapMarker
                        searchText="Search Your Stories Location"
                        isFormDisabled={isFormDisabled}
                        isAdvertisement={false}
                        onChange={(event) => setFormData({
                            ...formData,
                            storyLatitude: event.lat,
                            storyLongitude: event.lng,
                        })}
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Dropdown
                        ariaLabel="Primary Category Selector"
                        id="primary- category-selector"
                        items={storyCategories}
                        label="Select the Primary Category"
                        titleText="Select Your Category."
                        selectedItem={formData.storyPrimaryCategory ?
                            storyCategories.find(dbCategory => dbCategory.databaseId === parseInt(formData.storyPrimaryCategory)) : ''
                        }
                        initialSelectedItem={formData.storyPrimaryCategory ?
                            storyCategories.find(dbCategory => dbCategory.databaseId === parseInt(formData.storyPrimaryCategory)) : ''
                        }
                        onChange={(event) =>
                            setFormData({
                                ...formData,
                                storyPrimaryCategory: event.selectedItem.databaseId,
                            })
                        }
                    />

                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    {
                        isEdit ? formData.storyCategories && <MultiSelect
                            id="category-selector"
                            ariaLabel="Category Selector"
                            items={storyCategories}
                            initialSelectedItems={formData.storyCategories ? formData.storyCategories.map(
                                category => storyCategories.find(dbCategory => dbCategory.databaseId === parseInt(category))
                            ) : []}
                            label="Select Categories"
                            titleText="Select Your Categories"
                            disabled={isFormDisabled}
                            onChange={(event) =>
                                setFormData({
                                    ...formData,
                                    storyCategories: event.selectedItems.map(story => story.databaseId),
                                })
                            }
                        /> : <MultiSelect
                            id="category-selector"
                            ariaLabel="Category Selector"
                            items={storyCategories}
                            initialSelectedItems={formData.storyCategories ? formData.storyCategories.map(
                                category => storyCategories.find(dbCategory => dbCategory.databaseId === parseInt(category))
                            ) : []}
                            label="Select Categories"
                            titleText="Select Your Categories"
                            disabled={isFormDisabled}
                            onChange={(event) =>
                                setFormData({
                                    ...formData,
                                    storyCategories: event.selectedItems.map(story => story.databaseId),
                                })
                            }
                        />
                    }
                    {}
                </Column>
            </Row>
            <hr/>
            <Row space={spacing.small}>
                <Column>
                    <p>Upload your Video Story Here</p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <MediaUpload
                        uploadDescription={"Please select MP4 or MOV files 500Mb or Less"}
                        isVideo={true}
                        isImage={false}
                        existingFileUrl={existingFileUrlVideo}
                        isUploading={(uploadingStatus) => setIsMediaVideoUploading(uploadingStatus)}
                        acceptedFormats={[
                            '.mp4',
                            '.mov',
                        ]}
                        isFormDisabled={isFormDisabled}
                        selectFileChange={(event) => {
                            setFormData({
                                ...formData,
                                storyMediaId: 0,
                            })
                            setExistingFileUrlVideo(event)
                        }}
                        onChange={(event) => setFormData({
                            ...formData,
                            storyMediaId: event,
                        })}
                    />
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <p>Upload a Photo Thumbnail Showcasing Your Story</p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <MediaUpload
                        uploadDescription={"Please select JPG or PNG files 5Mb or Less"}
                        isVideo={false}
                        isImage={true}
                        existingFileUrl={existingFileUrlPhoto}
                        isUploading={(uploadingStatus) => setIsMediaPhotoUploading(uploadingStatus)}
                        acceptedFormats={[
                            '.png',
                            '.jpg',
                            '.jpeg',
                        ]}
                        isFormDisabled={isFormDisabled}
                        selectFileChange={(event) => {
                            setFormData({
                                ...formData,
                                thumbnailMediaId: 0,
                            })
                            setExistingFileUrlPhoto(event)
                        }}
                        onChange={(event) => setFormData({
                            ...formData,
                            thumbnailMediaId: event,
                        })}
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Checkbox
                        defaultChecked={formData.isFilmFestival}
                        labelText={
                            <span>I want to enter this Story into the Film Festival and Agree to the <a target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        href={endpoints.termsAndConditionsFilmFestival()}>
                                        Terms and Conditions
                                    </a>
                                </span>
                        }
                        id="terms-conditions-film-festival"
                        disabled={isFormDisabled}

                        onClick={(event) => {
                            setFormData({
                                ...formData,
                                isFilmFestival: event.target.checked,
                            })
                        }}
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Checkbox
                        defaultChecked={false}
                        labelText={
                            <span>I Agree to the <a target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={endpoints.termsAndConditions()}>
                                        Terms and Conditions
                                    </a>
                                </span>
                        }
                        id="terms-conditions"
                        value={agreedTermsConditions}
                        disabled={isFormDisabled}
                        onChange={(event) => setAgreedTermsConditions(event)}
                    />
                </Column>
            </Row>
            <Row className={style.termsAndConditions} space={spacing.small}>
                <Column>
                    <FormLabel>
                        Yesterday Stories Pty Ltd reserves the right not to publish any video or content including
                        but not limited to low sound and video quality, disputable history, stories which cause offence
                        or not aligned with community standards and expectation. Please see the &nbsp;
                        <a rel="noopener noreferrer" target="_blank"
                           href='https://media.yesterdaystories.net.au/docs/terms-and-conditions.pdf'>
                            Terms and Conditions
                        </a>
                        &nbsp;for more details.
                    </FormLabel>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isLoading={props.isLoading}
                        isSuccess={props.isSuccess}
                        isError={props.isError}
                        errorMessage={props.errorMessage}
                    />
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    {(createdStory && !isEdit) && (
                        <>
                            <InlineNotification
                                kind={'success'}
                                iconDescription="Story Created"
                                subtitle={(
                                    <>
                                        {createdStory.data.promoCode &&
                                        <p>Please use the promo code "{createdStory.data.promoCode}" for a free
                                            Advertisement</p>}
                                        <Link
                                            href={`/advertisements/create?promo=${createdStory.data.promoCode}&storyLock=${createdStory.data.storyId}`}>
                                            Create an Advertisement with the Promo Code here
                                        </Link>
                                        <br/>
                                        <Link href="/stories/list">Go back to the Story list here</Link>
                                    </>
                                )}
                                title="Story Created"
                                hideCloseButton={true}
                            />
                        </>
                    )}
                </Column>
            </Row>
            <Row space={spacing.large}>
                <Column>
                    <Button
                        kind="primary"
                        tabIndex={0}
                        disabled={props.isLoading || props.isSuccess || isMediaVideoUploading || isMediaPhotoUploading}
                        onClick={() => {
                            if (!formData.isFilmFestival) {
                                formData.isFilmFestival = false
                            }

                            props.submit(formData, isEdit, agreedTermsConditions)
                        }}
                    >
                        Save Story
                    </Button>
                </Column>
            </Row>
        </>
    )
}
