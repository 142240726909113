module.exports = {
    adTypesHash: {
        1: { amount: 1.99 },
        7: { amount: 4.99 },
        2: { amount: 14.99 },
        3: { amount: 44.99 },
        4: { amount: 4.99 },
        8: { amount: 9.99 },
        5: { amount: 39.99 },
        6: { amount: 99.99 },
    },
    adTypes: [
        {
            id: "mini-ad-1",
            databaseId: 1,
            text: "Mini Ads - 1 Story - $1.99 a month",
            amount: 1.99,
        },
        {
            id: "mini-ad-4",
            databaseId: 7,
            text: "Mini Ads - 3 Stories - $4.99 a month",
            amount: 4.99,
        },
        {
            id: "mini-ad-2",
            databaseId: 2,
            text: "Mini Ads - 10 Stories - $14.99 a month",
            amount: 14.99,
        },
        {
            id: "mini-ad-3",
            databaseId: 3,
            text: "Mini Ads - 25 Stories - $44.99 a month",
            amount: 44.99,
        },
        {
            id: "large-ad-4",
            databaseId: 4,
            text: "Main Ads - 1 Story - $4.99 a month",
            amount: 4.99,
        },
        {
            id: "large-ad-8",
            databaseId: 8,
            text: "Main Ads - 3 Stories - $9.99 a month",
            amount: 9.99,
        },
        {
            id: "large-ad-5",
            databaseId: 5,
            text: "Main Ads - 10 Stories - $39.99 a month",
            amount: 39.99,
        },
        {
            id: "large-ad-6",
            databaseId: 6,
            text: "Main Ads - 25 Stories - $99.99 a month",
            amount: 99.99,
        },
    ],
    storyCategories: [
        {
            id: "sites-landmarks",
            databaseName: "Sites & Landmarks",
            label: "Sites & Landmarks",
            text: "Sites & Landmarks",
            databaseId: 1,
        },
        {
            id: "our-communities",
            databaseName: "Our Communities",
            label: "Our Communities",
            text: "Our Communities",
            databaseId: 3,
        },
        {
            id: "major-events",
            databaseName: "Major Events",
            label: "Major Events",
            text: "Major Events",
            databaseId: 4,
        },
        {
            id: "personal-stories",
            databaseName: "Personal Stories",
            label: "Personal Stories",
            text: "Personal Stories",
            databaseId: 5,
        },
        {
            id: "indigenous",
            databaseName: "Indigenous",
            label: "Indigenous",
            text: "Indigenous",
            databaseId: 6,
        },
        {
            id: "military",
            databaseName: "Military",
            label: "Military",
            text: "Military",
            databaseId: 7,
        },
        {
            id: "paranormal",
            databaseName: "Paranormal",
            label: "Paranormal",
            text: "Paranormal",
            databaseId: 8,
        },
        {
            id: "industrial",
            databaseName: "Industrial",
            label: "Industrial",
            text: "Industrial",
            databaseId: 9,
        },
        {
            id: "artandmonuments",
            databaseName: "Art and Monuments",
            label: "Art and Monuments",
            text: "Art and Monuments",
            databaseId: 10,
        },
        {
            id: "crime",
            databaseName: "Crime",
            label: "Crime",
            text: "Crime",
            databaseId: 11,
        },
        {
            id: "colonial",
            databaseName: "Colonial",
            label: "Colonial",
            text: "Colonial",
            databaseId: 12,
        },
        {
            id: "environment",
            databaseName: "Environment",
            label: "Environment",
            text: "Environment",
            databaseId: 13,
        },
        {
            id: "political",
            databaseName: "Political",
            label: "Political",
            text: "Political",
            databaseId: 14,
        },
        {
            id: "museumsandgalleries",
            databaseName: "Museums and Galleries",
            label: "Museums and Galleries",
            text: "Museums and Galleries",
            databaseId: 15,
        },
    ],
    videoProcessingStatuses: {
        INITIATED: {
            databaseName: "INITIATED",
            friendlyName: "Initiating",
        },
        PROCESSING: {
            databaseName: "PROCESSING",
            friendlyName: "Processing",
        },
        COMPLETED: {
            databaseName: "COMPLETED",
            friendlyName: "Completed"
        },
    },
    defaultCompanyLocation: {
        latitude: -34.4255436,
        longitude: 150.8848503
    },
    defaultStoryLocation: {
        latitude: -34.4255436,
        longitude: 150.8848503
    },
    difficultyTypes: [
        {
            id: 'easy',
            text: 'Easy',
        },
        {
            id: 'medium',
            text: 'Medium',
        },
        {
            id: 'hard',
            text: 'Hard',
        },
    ]
};
